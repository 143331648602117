import { Category, ChartComponent, ColumnSeries, DataLabel, Inject, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective, Tooltip } from '@syncfusion/ej2-react-charts';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Col, Pagination, PaginationItem, PaginationLink, Row, Table, Button, Label, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../helpers/authentication';
import { Context } from '../../helpers/Context';
import { getCurrentDomain } from '../../helpers/Lookup';

export class OrderDashboard extends Component {
  //static endDate = new Date().toLocaleDateString('en-US', OrderDashboard.options);
  //static startDate = new Date().toLocaleDateString('en-US', OrderDashboard.options);
  static options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  static endDate = moment().add(1, "days").format("yyyy-MM-DD");
  static startDate = moment().add(-7, "days").format("yyyy-MM-DD");
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      editData: [], loading: true, editModal: false, notesModal: false, viewNotesModal: false,
      transactionHistory:[],
      Id: 0,
      Slug: '',
      Title: '',
      AddedBy: 0,
      DateAdded: new Date(),
      ModifiedBy: 0,
      DateModified: new Date(),
      reportStartDate: OrderDashboard.startDate, reportEndDate: OrderDashboard.endDate,
      dateRange: [{ startDate: new Date(OrderDashboard.startDate), endDate: new Date(OrderDashboard.endDate), key: "selection" }],
      reportRange: [null, null],
      Status: 0,
      ReportData: [],
      primaryXAxis: { valueType: 'Category' },
      selectAll: false,
      selectedItems: [],
      currentPage: 1,
      itemsPerPage: 10,
      UserList: [],
      orderBy: "OrderIDDown",
      Statuses: [
        { id: 1, name: 'Pending Payment' },
        { id: 2, name: 'Pending Delivery' },
        { id: 3, name: 'Complete' },
        { id: 10, name: 'User Cancelled' },
      ],
      searchQuery: '',
    };
    this.toggle = this.toggle.bind(this);
    this.toggleNotesEdit = this.toggleNotesEdit.bind(this);
    this.toggleNotesView = this.toggleNotesView.bind(this);
    this.sortBy = this.sortBy.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  toggleNotesEdit() {
    this.setState({
      notesModal: !this.state.notesModal
    });
  }

  toggleNotesView() {
    this.setState({
      viewNotesModal: !this.state.viewNotesModal
    });
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  componentDidMount() {
    document.title = "Order Dashboard :: TriggerCraft";
    this.loadData();
  }

  updateDateRange = async (event) => {
    let startDate = event[0].toLocaleDateString('en-US', OrderDashboard.options);
    let endDate = event[1].toLocaleDateString('en-US', OrderDashboard.options);
    this.setState({ reportStartDate: startDate, reportEndDate: endDate }, e => {
      this.setDefaultDate();
    });
  }

  setDefaultDate = () => {
    let workDate = new Date(this.state.reportStartDate);
    let dateRange = []

    while (workDate < new Date(this.state.reportEndDate)) {
      let newDate = new Date(workDate.toDateString());
      dateRange.push(newDate);
      workDate.setDate(workDate.getDate() + 1);
    }
    this.setState({ dateRange: dateRange, loading: true });
    this.loadData()
  }

  handleStatusChange = (order, selectedStatus, event) => {
    //console.log(selectedStatus, event)
    //event.stopPropagation();
    const data = {
      Amount: order.amount,
      CartId: order.cartId,
      DateComplete: selectedStatus === 3 ? new Date() : order.dateComplete,
      DateStarted: order.dateStarted,
      DateSubmitted: order.dateSubmitted,
      DeliveryAddressId: order.deliveryAddressId,
      DeliveryFee: order.deliveryFee,
      DeliveryMessage: order.deliveryMessage,
      DeliveryMeta: order.deliveryMeta,
      Id: order.id,
      Instructions: order.instructions,
      NumItems: order.numItems,
      OrderId: order.orderId,
      orderType: order.orderType,
      PaymentMessage: order.paymentMessage,
      PaymentMeta: order.paymentMeta,
      PromoCode: order.promoCode,
      Status: selectedStatus,
      StatusMessage: this.state.Statuses.find(item => { return item.id === selectedStatus }).name,
      StockCheck: order.stockCheck,
      UseCredit: order.useCredit,
      UserId: order.userId,
      Vat: order.vat,
    }

    if (event.isInteracted) this.updateStaus(data);

  }

  exportSurveyReport = (data) => {
    //console.log(data)
    if (data.length) {
      const filteredItems = data.filter(item => this.state.selectedItems.includes(item.id)).map(item => ({ ...item, orderId: "TC" + item.orderId.toString().padStart(4, '0'), deliveryFee: new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(item.deliveryFee), amount: new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(item.amount) }));

      let overview = filteredItems
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('TriggerCraft Orders')
      worksheet.columns = [
        { header: 'OrderID', key: 'orderId', width: 10 },
        { header: 'First Name', key: 'firstName', width: 20 },
        { header: 'First Name', key: 'lastName', width: 20 },
        { header: 'Date Started', key: 'dateStarted', width: 30 },
        { header: 'Date Submitted', key: 'dateSubmitted', width: 30 },
        { header: 'Date Completed', key: 'dateComplete', width: 30 },
        { header: 'Delivery Fee', key: 'deliveryFee', width: 15, style: { alignment: { horizontal: 'right' } } },
        { header: 'Status Message', key: 'statusMessage', width: 40 },
        { header: 'Payment Message', key: 'paymentMessage', width: 40 },
        { header: 'Amount', key: 'amount', width: 15, style: { alignment: { horizontal: 'right' } } },
        { header: 'Payment Meta', key: 'paymentMeta', width: 100 },
      ];
      worksheet.addRows(overview);

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        saveAs(blob, `Order_Report_(${new Date().toISOString().split("T")[0]}).xlsx`);

      })
    } else {
      alert('No Data to Export')
    }
  }

  toggleSelectAll = () => {
    const { selectAll } = this.state;
    this.setState({
      selectAll: !selectAll,
      selectedItems: !selectAll ? this.state.editData.map(item => item.id) : [],
    });
  };

  toggleItem = (id) => {
    const { selectedItems } = this.state;
    if (selectedItems.includes(id)) {
      this.setState({
        selectedItems: selectedItems.filter(id => id !== id),
      });
    } else {
      this.setState({
        selectedItems: [...selectedItems, id],
      });
    }
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
  };

  getUserName = (id) => {
    const name = this.state.UserList.find(item => { return item.id === id })
    //console.log(id, name)
    return `${name.firstName} ${name.lastName}`
  };

  handleSearchInputChange = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  viewOrder = async (id) => {
    const { getUserAddressAdmin } = this.context;
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id == id });
      try {
        let address = await getUserAddressAdmin(data.deliveryAddressId);

        this.setState({
          currentOrder: data.orderData,
          grandTotal: data.amount,
          deliveryFee: data.deliveryFee,
          instructions: data.instructions,
          selectedAddress: address,
          statusMessage: data.statusMessage,
          fullName: `${data.firstName} ${data.lastName}`,
          emailAddress: data.emailAddress,
          adminNotes: data.notes,
        });
      } catch (error) {
        console.error("Error fetching user address:", error);
      }
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Description: '',
        instructions: '',
        statusMessage: '',
        MaxUses: 0,
        NumUses: 0,
        StartDate: new Date(),
        EndDate: new Date(),
        Type: 0,
        Amount: 0,
        AddedBy: 0,
        DateAdded: new Date(),
        ModifiedBy: 0,
        DateModified: new Date(),
        Status: 0,
        deliveryFee: 0,
        grandTotal: 0,
        currentOrder: [],
      });
    }
    this.setState({ editId: id, editModal: true, AdminNote: "" });
  };

  renderTableRows = () => {
    const { selectAll, selectedItems, currentPage, itemsPerPage, editData, searchQuery, Statuses } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredItems = editData
      .filter((item) =>
        item.orderId.toString().includes(searchQuery) ||
        `${item.firstName} ${item.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
        new Date(item.dateStarted).toDateString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.statusMessage.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.amount.toString().includes(searchQuery)
      )
      .slice(indexOfFirstItem, indexOfLastItem);

    return filteredItems.sort((a, b) => {

      switch (this.state.orderBy) {
        case "OrderIDDown":
          return b.orderId - a.orderId;
        case "CustomerDown":
          return a.firstName.localeCompare(b.firstName);
        case "CustomerUp":
          return b.firstName.localeCompare(a.firstName);
        case "DateDown":
          return new Date(a.dateSubmitted) - new Date(b.dateSubmitted);
        case "DateUp":
          return new Date(b.dateSubmitted) - new Date(a.dateSubmitted);
        case "AmountDown":
          return a.amount - b.amount;
        case "AmountUp":
          return b.amount - a.amount;
        default:
          return a.orderId - b.orderId;
      }
    }).map((item, index) => (
      <tr key={index} style={{ cursor: "pointer" }}>
        <td>
          <input
            type="checkbox"
            checked={selectAll || selectedItems.includes(item.id)}
            onChange={() => this.toggleItem(item.id)}
          />
        </td>
        <td onClick={() => this.viewOrder(item.id)}>{"TC" + item.orderId.toString().padStart(4, '0')}</td>
        <td onClick={() => this.viewOrder(item.id)}>{`${item.firstName} ${item.lastName}`}</td>
        <td onClick={() => this.viewOrder(item.id)}>{item.dateSubmitted ? new Date(item.dateSubmitted).toDateString() : ""}</td>
        <td>
          <DropDownListComponent id='ddStatus' name='ddStatus' dataSource={Statuses} fields={{ text: 'name', value: 'id' }} value={item.status} change={e => this.handleStatusChange(item, e.value, e)} />
        </td>
        <td className="text-end pointer" onClick={() => this.viewOrder(item.id)}>{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(item.amount)}</td>
      </tr>
    ));
  };

  render() {
    const { selectAll, currentPage, itemsPerPage } = this.state;
    const totalItems = this.state.editData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const { handleNavigate } = this.context;
    //const { currentPage, itemsPerPage } = this.state;
    //const indexOfLastItem = currentPage * itemsPerPage;
    //const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    //const totalPages = Math.ceil(data.length / itemsPerPage);
    //console.log(this.state)
    return (
      <>
        <div className="container">
          <Row className='mb-3'>
            <Col xs={6}>
              <h1 className='text-dark fw-light'>Order Dashboard</h1>
            </Col>
            <Col md={6} className="text-end align-self-center">
              <Button className="mx-2" color="dark" outline size="sm" data-recordid="0" onClick={e => handleNavigate("/admin/administration")}><i className="fas fa-chevron-left mr-2"></i> Back To Admin</Button>
              <Link to="/admin" className="btn btn-sm text-light" style={{ background: "#74bc1f" }} >Admin dashboard <i className='fas fa-arrow-circle-right'></i></Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className='card card-body shadow mb-4'>
                <b>Filter</b>
                <div className='row'>
                  <div className='col-md'>
                    <Label className="mb-0"><small className='text-muted fw-semibold fs-7'> Date range</small></Label>
                    <br />
                    <DatePicker
                      className='border-style'
                      selectsRange={true}
                      placeholderText='Select Dates...'
                      startDate={this.state.reportRange[0]}
                      endDate={this.state.reportRange[1]}
                      dateFormat='yyyy-MM-dd'
                      onChange={(update) => [this.setState({ reportRange: update, storeLoader: true, channelLoader: true }), update[1] && this.updateDateRange(update)]}
                      isClearable={false} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : <Col xs={12}>
              <div className='d-flex graphs shadow mb-4'>
                <div className="col-md-6">
                  <ChartComponent id='charts' primaryXAxis={this.state.primaryXAxis}>
                    <Inject services={[LineSeries, Legend, Tooltip, DataLabel, Category]} />
                    <SeriesCollectionDirective>
                      <SeriesDirective dataSource={this.state.ReportData} xName='x' yName='y1' name='Sales in (R)' type='Line' fill='#74bc1f' marker={{ visible: true, width: 10, height: 10, shape: "Circle", isFilled: true }}>
                      </SeriesDirective>
                    </SeriesCollectionDirective>
                  </ChartComponent>
                </div>
                <div className="col-md-6">
                  <ChartComponent id='combo' primaryXAxis={this.state.primaryXAxis} tooltip={{ enable: true }} legendSettings={{ visible: true }}>
                    <Inject services={[ColumnSeries, Category, DataLabel, Legend, Tooltip]} />
                    <SeriesCollectionDirective>
                      <SeriesDirective dataSource={this.state.ReportData} xName='x' yName='y2' type='Column' name='Number of orders' fill='#74bc1f' marker={{ dataLabel: { visible: false, position: 'Top', font: { fontWeight: '600', color: '#333' } } }} />
                    </SeriesCollectionDirective>
                  </ChartComponent>
                </div>
              </div>
            </Col>}
          </Row>
          <Row>
            {this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : <Col xs={12}>
              <div className="card mb-3 p-4">
                {this.state.selectedItems.length ? <button className="checkout-btn mb-2" onClick={() => this.exportSurveyReport(this.state.editData)}>Export to Excel</button> :
                  <button className='checkout-btn mb-2' onClick={() => ToastUtility.show({
                    title: 'Orders', content: 'Select Items to Export!', timeOut: 2000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-info'
                  })}>Export to Excel</button>}
                <input placeholder="search" className="mb-3" value={this.state.searchQuery} onChange={this.handleSearchInputChange} disabled={this.state.loading} />

                <>
                  <div className="table-responsive">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" checked={selectAll} onChange={this.toggleSelectAll} />
                          </th>
                          <th className="fw-bold" onClick={() => this.sortBy("OrderID")}>OrderID</th>
                          <th className="fw-bold" onClick={() => this.sortBy("Customer")}>Customer</th>
                          <th className="fw-bold" onClick={() => this.sortBy("Date")}>Date</th>
                          <th className="fw-bold">Status</th>
                          <th className="fw-bold text-end" onClick={() => this.sortBy("Amount")}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderTableRows()}
                      </tbody>
                    </Table>
                  </div>

                  <Pagination>
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink previous onClick={() => this.handlePageClick(currentPage - 1)} />
                    </PaginationItem>
                    {[...Array(totalPages)].map((_, i) => (
                      <PaginationItem key={i} active={i + 1 === currentPage}>
                        <PaginationLink onClick={() => this.handlePageClick(i + 1)}>{i + 1}</PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink next onClick={() => this.handlePageClick(currentPage + 1)} />
                    </PaginationItem>
                  </Pagination>
                </>
              </div>
            </Col>}
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Order Details</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <h4>Product Info</h4>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="fw-bold" scope="col">Product Name</th>
                    <th className="fw-bold" scope="col">Product ID</th>
                    <th className="fw-bold" scope="col">SKU</th>
                    <th className="fw-bold" scope="col">Quantity</th>
                    <th className="fw-bold" scope="col">Payment Status</th>
                    <th className="fw-bold" scope="col">Sub Total</th>
                    <th className="fw-bold" scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.currentOrder && this.state.currentOrder.map((data, index) => (
                    <tr className="history" key={index}>
                      <td><small>{data.Title}</small></td>
                      <td>{data.ProductId}</td>
                      <td>{data.Sku}</td>
                      <td><small>{data.Qty}</small></td>
                      <td><small>{this.state.statusMessage}</small></td>
                      <td className="text-end"><small>{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(data.PriceCharged)}</small></td>
                      <td className="text-end"><small>{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(data.TotalCharged)}</small></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <h4>Customer Details</h4>
              {this.state.editModal && <>
                <p><strong>Customer Name: </strong>{this.state.fullName}</p>
                <p><strong>Email: </strong>{this.state.emailAddress}</p>
                <p><strong>Address: </strong>{this.state.selectedAddress.address}</p>
                <p><strong>Company: </strong>{this.state.selectedAddress.company}</p>
                <p><strong>Apartment: </strong>{this.state.selectedAddress.apartment}</p>
                <p><strong>Suburb: </strong>{this.state.selectedAddress.suburb}</p>
                <p><strong>City: </strong>{this.state.selectedAddress.city}</p>
                <p><strong>Province: </strong>{this.state.selectedAddress.province}</p>
                <p><strong>Postal Code: </strong>{this.state.selectedAddress.postalCode}</p>
                <p><strong>Mobile Number: </strong>{this.state.selectedAddress.mobileNumber}</p>
                <p><strong>Instructions: </strong>{this.state.instructions}</p></>}
              <div className='w-100'>
                <div className="text-end row" style={{ borderTop: "2px solid black" }}>
                  <strong className="col text-end" style={{ color: "#74bc1f" }}>TOTAL</strong>
                  <strong className="col-md-2 pe-0">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.state.currentOrder && this.state.currentOrder.reduce((previousValue, currentValue) => {
                    return previousValue + currentValue.TotalCharged;
                  }, 0))}</strong>
                </div>
                <div className="text-end row">
                  <strong className="col text-end" style={{ color: "#74bc1f" }}>DELIVERY FEE</strong>
                  <strong className="col-md-2 pe-0">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.state.deliveryFee)}</strong>
                </div>
                <div className="text-end row">
                  <strong className="col text-end" style={{ color: "#74bc1f" }}>GRAND TOTAL</strong>
                  <strong className="col-md-2 pe-0" style={{ borderTop: "2px solid black", borderBottom: "4px double black" }} >{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(this.state.grandTotal)}</strong>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className='d-flex justify-content-between'>
            {this.state.adminNotes?.length > 0 ? <Button color="info" size="sm" onClick={() => { this.toggleNotesView(); this.toggle() }}>View Note Admin </Button> : <div></div>}
            <div>
              <Button className="me-2" color="info" size="sm" onClick={() => { this.toggleNotesEdit(); this.toggle() }}>Add Note <i className="fas fa-plus-circle ms-2"></i></Button>
              <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.notesModal} toggle={this.toggleNotesEdit} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggleNotesEdit} close={<button className="close" onClick={this.toggleNotesEdit}><i className="fas fa-times"></i></button>}>Add Note</ModalHeader>
          <ModalBody>
            <div className='mb-2'>
              <TextBoxComponent multiline id='tbAdminNote' name='tbAdminNote' placeholder='Admin Note' type='text' maxLength='350' floatLabelType='Always' showClearButton={true} value={this.state.AdminNote} onChange={e => this.setState({ AdminNote: e.target.value })} /> <div id='tbAdminNoteError' className='error-message' />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={() => { this.toggleNotesEdit(); this.toggle(); }}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button style={{ background: "#74bc1f", color: "#fff" }} size="sm" onClick={() => this.saveNote()}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.viewNotesModal} toggle={this.toggleNotesView} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggleNotesView} close={<button className="close" onClick={this.toggleNotesView}><i className="fas fa-times"></i></button>}>Admin Notes</ModalHeader>
          <ModalBody>
            <div className='mb-2'>
              {this.state.adminNotes?.length > 0 ? this.state.adminNotes.map((item, index) => (
                <div key={index} class={item.AddedBy == getUserDetails().id ? "speech-bubble sb2" : "speech-bubble sb1"}>
                  <small className='mb-3 fw-normal'>{item.UserName} - {new Date(item.DateAdded).toDateString()}</small>
                  <br></br>
                  <span>{item.AdminNote}</span>
                </div>)) :
                <h6>No notes for this order</h6>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={() => { this.toggleNotesView(); this.toggle(); }}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {
    const { getAllProducts } = this.context;

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/cart', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data.map(item => ({ ...item, orderData: JSON.parse(item.orderData), notes: JSON.parse(item.notes) })).filter(item => item.status !== 0)
        //data = data.filter(item => item.status !== 0)
        this.setState({ editData: data, ProductList: await getAllProducts() });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/cart/getsalesdata/${this.state.reportStartDate}/${this.state.reportEndDate}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        //console.log(data)
        this.setState({ ReportData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/cart/getpayfasthistory`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        console.log(data)
        this.setState({ transactionHistory: data});
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveNote() {
    const userData = getUserDetails();
    this.setState({ loading: true, showError: false, showSuccess: false, notesModal: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: 0, CartId: this.state.editId, AdminNote: this.state.AdminNote, AddedBy: userData.id, DateAdded: new Date(), Status: 1, }

    try {
      const response = await fetch('api/adminordernotes', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        await this.loadData();
        this.viewOrder(this.state.editId);
        ToastUtility.show({
          title: 'Admin Notes', content: 'The admin note was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Admin Notes', content: 'There was an error saving the admin note!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async updateStaus(data) {
    const { sendCustomEvent } = this.context;
    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch('api/cart', {
        method: 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Order Status', content: 'The order status was successfully updated!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
        sendCustomEvent("Order Status", "Updated Order Status", `${data.Status}`)
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Order Status', content: 'There was an error updating the order status!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/bannersections/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Order Dashboard', content: 'The Order was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Order Dashboard', content: 'There was an error deleting the Order!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

